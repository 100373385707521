import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/account-takeover/case-study",
    label: "CASE STUDY",
  },
]

const RiskReduction = () => (
  <Layout>
    <Seo title="CASE STUDY" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <div className="grid--bg--for-it-ops-csy-at">
      <section className="section-bio pt-5">
        <div className="container">
          <div className="row">
            <div className="pcsy">
              <div className="section-col-cs">
                <h4>CASE STUDY</h4>
                <hr />
              </div>
              <div className="col-sm-7">
                <h1>
                  ANY ACTIVE ACCOUNT <br /> IS A THREAT
                </h1>
                <h3>Vigilant365™</h3>
              </div>
              <div className="col-sm-4">
                <p className="pt-5">
                  A client contracted with Vigilant to secure and protect their
                  environments. The contract included monitoring and securing
                  each of their 287 login accounts, using our Vigilant365™
                  service, which involves password security, login locations,
                  user activity, etc., with immediate, real-time visibility
                  inside the CyberDNA® Command and Control Center, allowing the
                  client to monitor and collaborate in all we were seeing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section className="section-blocks-6">
      <div className="container">
        <div className="csy pt-5 col-sm-10">
          <p>
            But there was an unexpected discovery. It turned out there were
            actually 750 accounts – many more than the original 287 accounts the
            client had disclosed. Where did these nearly-500 unaccounted for
            logins come from? Vigilant365™ provides custom alerting over and
            beyond what Microsoft (Office 365) can offer. All that data is
            ingested into our Vigilant platform, assessed and presented back to
            the client in understandable and actionable ways. As a result, every
            account was vetted, source confirmed and every invalid account
            removed.
          </p>
        </div>
      </div>
    </section>

    <div className="text-center pb-5">
      <Link to="/contact/" className="nav-section--cta-btn">
        <div className="d-flex align-items-center">
          <span
            style={{ marginLeft: "25px" }}
            className="nav-section--cta-title pr-5"
          >
            schedule a call
          </span>
          <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
        </div>
      </Link>
    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default RiskReduction
